import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import BaseDataMostrar from '../BaseDataMostrar'
import { capitalizeWord, getTextFromBoolean } from 'utils/general'

export default function IngresoPreexistenteVer(props) {
	const { config, tarea } = props

	const propietario = config.propietarios.find(propietario => propietario.id === tarea.carga.propietarioId)
	const establecimiento = config.establecimientos.find(establecimiento => establecimiento.id === tarea.carga.establecimientoId)

	return (
		<Container>
			<Row>
				<Col>
					<h3>{tarea.nombre}</h3>
				</Col>
			</Row>
			<BaseDataMostrar
				usuarios={config.usuarios}
				data={{
					nombre: tarea.nombre,
					fechaParaCuando: tarea.fechaParaCuando,
					asignadoId: tarea.asignadoId || '',
					observacion: tarea.observacion
				}}
			/>
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Datos</Card.Title>
					<Row>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Propietario</th>
										<td>{`${propietario.nombre} ${propietario.apellido}`}</td>
									</tr>
									<tr>
										<th>Establecimiento</th>
										<td>{establecimiento.nombre}</td>
									</tr>
									{tarea.carga.tipo === 'rango' ? (
										<>
											<tr>
												<th>Categoría</th>
												<td>{tarea.carga.categoria.nombre}</td>
											</tr>
											<tr>
												<th>Sexo</th>
												<td>{tarea.carga.sexo}</td>
											</tr>
										</>
									) : null}
								</tbody>
							</Table>
						</Col>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Preñada</th>
										<td>{getTextFromBoolean(tarea.carga.prenada)}</td>
									</tr>
									<tr>
										<th>En servicio</th>
										<td>{getTextFromBoolean(tarea.carga.enServicio)}</td>
									</tr>
									<tr>
										<th>En posparto</th>
										<td>{getTextFromBoolean(tarea.carga.enPosparto)}</td>
									</tr>
									<tr>
										<th>Con cría al pie</th>
										<td>{getTextFromBoolean(tarea.carga.alPie)}</td>
									</tr>
									<tr>
										<th>Destete precoz</th>
										<td>{getTextFromBoolean(tarea.carga.destetePrecoz)}</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col md="7">
							<Card.Title>Cantidad de animales: <strong>{tarea.carga.cantidad}</strong></Card.Title>
							<Card.Subtitle>Tipo: <strong>{tarea.carga.tipo}</strong></Card.Subtitle>
							{tarea.carga.tipo === 'rango' ? (
								<Table>
									<tbody>
										<tr>
											<th>Con identificación</th>
											<td>{getTextFromBoolean(tarea.carga.conIdentificacion)}</td>
										</tr>
										{tarea.carga.conIdentificacion ? (
											<>
												<tr>
													<th>Tipo identificación</th>
													<td>{tarea.carga.tipoIdentificacion}</td>
												</tr>
												<tr>
													<th>Núm. Inicial</th>
													<td>{tarea.carga.numInicial}</td>
												</tr>
												<tr>
													<th>Núm. Final</th>
													<td>{parseInt(tarea.carga.numInicial) + tarea.carga.cantidad}</td>
												</tr>
											</>
										) : null}
										<tr>
											<th>Carimbo</th>
											<td>{tarea.carga.carimbo ?? '-'}</td>
										</tr>
										<tr>
											<th>Raza</th>
											<td>{tarea.carga.raza ?? '-'}</td>
										</tr>
										<tr>
											<th>Sexo</th>
											<td>{tarea.carga.sexo ?? '-'}</td>
										</tr>
									</tbody>
								</Table>
							) : (
								<Table>
									<thead>
										<tr>
											<th>#</th>
											<th>Núm. Chip</th>
											<th>Núm. Caravana</th>
											<th>Núm. Tatuaje</th>
											<th>Carimbo</th>
											<th>Raza</th>
											<th>Color</th>
											<th>Categoría</th>
											<th>Sexo</th>
										</tr>
									</thead>
									<tbody>
										{tarea.listas[0].animales.map((animal, i) => {
											return (
												<tr key={animal.id}>
													<td>{i + 1}</td>
													<td>{animal.numChip ?? '-'}</td>
													<td>{animal.numCaravana ?? '-'}</td>
													<td>{animal.numTatuaje ?? '-'}</td>
													<td>{animal.carimbo}</td>
													<td>{animal.raza}</td>
													<td>{animal.color}</td>
													<td>{animal.categoria.nombre}</td>
													<td>{animal.sexo}</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							)}
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container >
	)
}