import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

const IngresoDesdeExcel = ({ animales, onSave, onClose, saveDisabled }) => {
	return (
		<>
			<div className="mb-3">
				<h5>Animales seleccionados:</h5>
				<div
					className="table-responsive"
					style={{
						maxHeight: '400px',
						overflowY: 'auto',
						border: '1px solid #dee2e6',
						borderRadius: '4px',
						boxShadow: 'inset 0 -10px 10px -10px rgba(0,0,0,0.1)'
					}}
				>
					<Table striped bordered hover size="sm" className="mb-0">
						<thead style={{
							position: 'sticky',
							top: 0,
							backgroundColor: 'white',
							zIndex: 1,
							boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
						}}>
							<tr>
								<th>Categoría</th>
								<th>Sexo</th>
								<th>Núm. Chip</th>
								<th>Núm. Caravana</th>
								<th>Núm. Tatuaje</th>
								<th>Carimbo</th>
								<th>Raza</th>
								<th>Color</th>
							</tr>
						</thead>
						<tbody>
							{animales.map((animal, index) => (
								<tr key={index}>
									<td>{animal.categoria}</td>
									<td>{animal.sexo}</td>
									<td>{animal.numChip}</td>
									<td>{animal.numCaravana}</td>
									<td>{animal.numTatuaje}</td>
									<td>{animal.carimbo}</td>
									<td>{animal.raza}</td>
									<td>{animal.color}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</div>
			<div className="d-flex justify-content-end gap-2">
				<Button variant="secondary" onClick={onClose}>
					Cancelar
				</Button>
				<Button
					variant="primary"
					onClick={() => onSave(animales)}
					disabled={saveDisabled}
				>
					Importar
				</Button>
			</div>
		</>
	)
}

export default IngresoDesdeExcel