import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import Accordion from 'react-bootstrap/Accordion'
import BaseDataMostrar from '../BaseDataMostrar'
import { getShortDisplayDate } from 'utils/date'

export default function SanitacionVer(props) {
	const { config, tarea } = props

	const [listaI, setListaI] = useState(null)

	const handleSelect = (cual) => {
		if (cual === listaI) {
			setListaI(null)
		} else {
			setListaI(cual)
		}
	}

	const configuracionMedicamentosMap = {}
	tarea.sanitacion.configuracionMedicamentos.forEach(medicamento => {
		configuracionMedicamentosMap[medicamento.id] = medicamento.nombre
	})

	const formatearMedicamentosYDosis = (sanitacion) => {
        if (!sanitacion?.medicamentos) return '-'
        try {
            const medicamentos = JSON.parse(sanitacion.medicamentos)
            const medicamentosArray = Object.entries(medicamentos).map(([medicamentoId, dosis]) => {
                const nombreMedicamento = configuracionMedicamentosMap[medicamentoId]
                return {
                    nombre: nombreMedicamento,
                    dosis: dosis
                }
            })

            if (medicamentosArray.length === 0) return '-'

            return (
                <ul className="list-unstyled mb-0">
                    {medicamentosArray.map((med, index) => (
                        <li key={index}>
                            <strong>{med.nombre}:</strong> {med.dosis}
                        </li>
                    ))}
                </ul>
            )
        } catch (error) {
            console.error('Error al parsear medicamentos:', error)
            return '-'
        }
    }


	return (
		<Container>
			<Row>
				<Col>
					<h3>{tarea.nombre}</h3>
				</Col>
			</Row>
			<BaseDataMostrar
				usuarios={config.usuarios}
				data={{
					nombre: tarea.nombre,
					fechaParaCuando: tarea.fechaParaCuando,
					asignadoId: tarea.asignadoId || '',
					observacion: tarea.observacion
				}}
			/>
			{tarea.comentarioEncargado && <><h5 className="mt-3 mb-1">Comentario encargado</h5><Alert variant="info" className="">{tarea.comentarioEncargado}</Alert></>}
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Resumen</Card.Title>
					<Row>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Total procesados</th>
										<td>{`${tarea.listas[0].cantidadAnimalesYaProcesados} / ${tarea.listas[0].cantidadAnimales}`}</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card>
				<Card.Body>
					<h5 className="mt-3 mb-1">Medicamentos</h5>

					<Table striped bordered hover>
						<thead>
							<tr>
								<th>#</th>
								<th>Nombre</th>
							</tr>
						</thead>
						<tbody>
							{tarea.sanitacion.configuracionMedicamentos.map((medicamento, i) => (
								<tr key={`lista-medicamento${i}`}>
									<td>{i + 1}</td>
									<td>{medicamento.nombre}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Animales</Card.Title>
					<Table>
						<thead>
							<tr>
								<th>#</th>
								<th>Procesado</th>
								<th>Núm. chip</th>
								<th>Núm. caravana</th>
								<th>Carimbo</th>
								<th>Raza</th>
								<th>Categoría</th>
								<th>Sexo</th>
								<th>Fecha</th>
								<th>Medicamentos y dosis</th>
								<th>Profesional</th>
							</tr>
						</thead>
						<tbody>
							{tarea.listas[0].animales.map((animal, i) => {
								//const propietario = config.propietarios.find(propietario => propietario.id === sanitacion.animal.propietarioId)
								//const establecimiento = config.establecimientos.find(establecimiento => establecimiento.id === sanitacion.animal.establecimientoId)
								const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(animal.categoriaId))

								return <tr key={i}>
									<td>{i + 1}</td>
									<td className="text-center">{animal.procesado ? <i className="fa-solid fa-circle-check fa text-success"></i> : '-'}</td>
									<td>{animal['numChip'] || '-'}</td>
									<td>{animal['numCaravana'] || '-'}</td>
									<td>{animal.carimbo}</td>
									<td>{animal.raza}</td>
									<td>{categoria.nombre}</td>
									<td>{animal.sexo}</td>
									<td>{animal.sanitacion?.fecha ? getShortDisplayDate(animal.sanitacion?.fecha) : '-'}</td>
									<td>{formatearMedicamentosYDosis(animal.sanitacion)}</td>
									<td>{animal.sanitacion?.profesional || '-'}</td>
								</tr>
							})}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</Container >
	)
}