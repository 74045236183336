import React, { useState, useEffect, useRef } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { TIPOS_IDENTIFICACION } from 'config/generales'
import { CategoriaAnimalesSelects } from 'components/CategoriaAnimalesSelects'
import { debounce } from 'utils/debounce'
import { DEFAULT_CATEGORIA_ID, DEFAULT_SEXO, OPCIONES_DE_A_CUANTOS, DEFAULT_COLS } from 'config/seleccionAnimales'
import Paginacion from 'components/Paginacion'
import { numberWithSeparator } from 'utils/numberWithSeparator'
import api from 'api/api'

export default function SeleccionAnimales(props) {
	const { config, loading, animales, seleccionados, cambiarCategoria, filtros: filtrosOriginales, cambiarFiltro, seleccionarAnimal, seleccionarMultiplesAnimales,
		pagina, cambiarPagina, deACuantos, cambiarDeACuantos, numResultados, mostrarEliminados = false, permitirNoSeleccionarCategorias = true, cols = DEFAULT_COLS, containerRef = window } = props

	const opcionesDeACuantos = OPCIONES_DE_A_CUANTOS
	const tiposIdentificacion = TIPOS_IDENTIFICACION
	const defaultCategoriaId = DEFAULT_CATEGORIA_ID
	const defaultSexo = DEFAULT_SEXO

	const loadingCount = useRef(0)
	const [loadingInicial, setLoadingInicial] = useState(true)
	const [filtros, setFiltros] = useState(() => {
		const datos = {
			tipoIdentificacion: 'caravana',
			numIdentificador: '',
			propietarioId: '',
			establecimientoId: '',
			loteId: '',
			categoriaId: filtrosOriginales?.categoriaId || defaultCategoriaId,
			sexo: filtrosOriginales?.sexo || defaultSexo,
			carimbo: '',
			raza: '',
			color: '',
			peso: '',
			tareaId: ''
		}

		return datos
	})

	const opcionesFiltroPropietario = [
		{ label: 'Todos', value: '' },
		...config.propietarios.map(propietario => ({
			label: `${propietario.nombre} ${propietario.apellido}`,
			value: propietario.id
		}))
	]

	const opcionesFiltroEstablecimiento = [
		{ label: 'Todos', value: '' },
		...config.establecimientos.map(establecimiento => ({
			label: establecimiento.nombre,
			value: establecimiento.id
		}))
	]

	const opcionesFiltroLote = [
		{ label: 'Todos', value: '' },
		...config.lotes.map(lote => ({
			label: lote.nombre,
			value: lote.id
		}))
	]

	/*
	const handleChangeFiltros = (valor, cual) => {
		setFiltros(prev => ({
			...prev,
			[cual]: valor
		}))

		debounced(valor, cual)
	}
	*/

	const opcionesInicialesFiltroTarea = [
		...config.tareas.map(tarea => ({
			nombre: String(tarea.nombre),
			value: String(tarea.id)
		}))
	]

	const [opcionesTarea, setOpcionesTarea] = useState([])
	const [isLoadingBusquedaTareas, setIsLoadingBusquedaTareas] = useState(false);
	const [tareaInputQuery, setTareaInputQuery] = useState('');
	const handleChangeFiltros = (valor, cual) => {

		setFiltros(prev => ({
			...prev,
			[cual]: valor
		}));

		debounced(valor, cual)
	}

	const onSaveSelectCategoria = (categoriaData) => {
		if (filtros.categoriaId !== categoriaData.categoriaId || filtros.sexo !== categoriaData.sexo) {
			setFiltros(prev => ({
				...prev,
				...categoriaData
			}))

			cambiarCategoria(categoriaData)
		}
	}

	const debounced = React.useCallback(
		debounce(cambiarFiltro, 400),
		[]
	)

	const handleBusquedaTareas = async (query) => {
		setIsLoadingBusquedaTareas(true)

		const serverData = {
			filtros: { nombre: query }
		}

		try {
			const response = await api.post('u/ver-tareas-resumidas', serverData)
			const tareasFormateadas = response.datos.tareas.map(tarea => ({
				nombre: String(tarea.nombre),
				value: String(tarea.id)
			}))

			setOpcionesTarea([
				...tareasFormateadas
			])
		} catch (error) {
			console.error('Error obteniendo datos:', error)
			// En caso de error, volvemos a las opciones iniciales
			setOpcionesTarea(opcionesInicialesFiltroTarea)
		} finally {
			setIsLoadingBusquedaTareas(false)
		}
	}

	const handleInputChange = (text) => {
		setTareaInputQuery(prev => text)

		if (!text.trim()) {
			setOpcionesTarea(opcionesInicialesFiltroTarea)
		} else {
			debouncedBusquedaTareas(text)
		}
	}

	const debouncedBusquedaTareas = React.useCallback(
		debounce(handleBusquedaTareas, 400),
		[]
	);

	useEffect(() => {
		if (!loading) {
			loadingCount.current = loadingCount.current + 1;
			// Si la variable llega a 2, cambiar el estado de loadingInicial a false
			if (loadingCount.current === 2) {
				setLoadingInicial(false);
			}
		}
	}, [loading])

	return (
		<div className="animal-selector">
			<Card className="mt-3">
				<Card.Header as='h5'>Selección de animales</Card.Header>
				<Card.Body>
					<div className="filtros-wrapper mb-3">
						<Row>
							<Col>
								<Form.Group className="mb-1" controlId="seleccion-animales-tipoIdentificador">
									<Form.Label className="mb-1">Tipo de identificador</Form.Label>
									<Form.Select
										size="sm"
										onChange={(e) => handleChangeFiltros(e.target.value, 'tipoIdentificacion')}
										value={filtros.tipoIdentificacion}
									>
										{tiposIdentificacion.map((item, index) => {
											return (
												<option value={item.toLowerCase()} key={`tipo-identificacion-${index}`}>
													{item}
												</option>
											)
										})}
									</Form.Select>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className="mb-1" controlId="seleccion-animales-numIdentificador">
									<Form.Label className="mb-1">Núm. Identificador</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={filtros.numIdentificador}
										onChange={(e) => handleChangeFiltros(e.target.value, 'numIdentificador')}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Group controlId="seleccion-animales-propietarioId" className="col mb-1">
								<Form.Label className="mb-1">Propietario</Form.Label>
								<Form.Select
									size="sm"
									value={filtros.propietarioId}
									onChange={(e) => handleChangeFiltros(e.target.value, 'propietarioId')}
								>
									{opcionesFiltroPropietario.map((opcion, i) => {
										return (
											<option value={opcion.value} key={`seleccion-animales-propietario-${opcion.value}`}>
												{opcion.label}
											</option>
										)
									})}
								</Form.Select>
							</Form.Group>
							<Form.Group controlId="seleccion-animales-establecimientoId" className="col mb-1">
								<Form.Label className="mb-1">Establecimiento</Form.Label>
								<Form.Select
									size="sm"
									value={filtros.establecimientoId}
									onChange={(e) => handleChangeFiltros(e.target.value, 'establecimientoId')}
								>
									{opcionesFiltroEstablecimiento.map((opcion, i) => {
										return (
											<option value={opcion.value} key={`seleccion-animales-establecimiento-${opcion.value}`}>
												{opcion.label}
											</option>
										)
									})}
								</Form.Select>
							</Form.Group>
							<Form.Group controlId="seleccion-animales-lote" className="col mb-1">
								<Form.Label className="mb-1">Lote</Form.Label>
								<Form.Select
									size="sm"
									value={filtros.loteId}
									onChange={(e) => handleChangeFiltros(e.target.value, 'loteId')}
								>
									{opcionesFiltroLote.map((opcion, i) => {
										return (
											<option value={opcion.value} key={`seleccion-animales-lote-${opcion.value}`}>
												{opcion.label}
											</option>
										)
									})}
								</Form.Select>
							</Form.Group>
						</Row>
						<Row>
							<CategoriaAnimalesSelects
								opciones={config.categorias}
								loading={loading}
								initialCategoriaId={filtros.categoriaId}
								initialSexo={filtros.sexo}
								onSave={onSaveSelectCategoria}
								inline
								allowNoSelect={permitirNoSeleccionarCategorias}
							/>
						</Row>
						<Row>
							<Col>
								<Form.Group className="mb-1" controlId="seleccion-animales-carimbo">
									<Form.Label className="mb-1">Carimbo</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={filtros.carimbo}
										onChange={(e) => handleChangeFiltros(e.target.value, 'carimbo')}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className="mb-1" controlId="seleccion-animales-raza">
									<Form.Label className="mb-1">Raza</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={filtros.raza}
										onChange={(e) => handleChangeFiltros(e.target.value, 'raza')}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className="mb-1" controlId="seleccion-animales-color">
									<Form.Label className="mb-1">Color</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={filtros.color}
										onChange={(e) => handleChangeFiltros(e.target.value, 'color')}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group className="mb-1" controlId="seleccion-animales-tarea">
									<Form.Label className="mb-1">Tarea</Form.Label>
									<AsyncTypeahead
										id="async-tareas"
										isLoading={isLoadingBusquedaTareas}
										labelKey="nombre"
										onSearch={handleBusquedaTareas}
										options={opcionesInicialesFiltroTarea}
										placeholder="Buscar por tarea..."
										emptyLabel="No se encontraron tareas"
										minLength={0}
										onInputChange={handleInputChange}
										clearButton
										onChange={(selected) => {
											const valor = selected.length > 0 ? selected[0].value : '';
											handleChangeFiltros(valor, 'tareaId');
										}}
									/>
								</Form.Group>
							</Col>
						</Row>
					</div>
					{!animales.length && !loading ? (
						<Alert variant="info">No hay resultados</Alert>
					) : (
						<>
							<Row className="mb-1">
								<Col>
									<small>{numberWithSeparator('' + numResultados)} animales encontrados.</small>
									{/*max && selectedList.length >= max && <div className="text-muted"><small>Máximo <strong>{max}</strong> animales.</small></div>*/}
								</Col>
								<Col className="text-end">
									<Button size="sm"
										onClick={() => seleccionarMultiplesAnimales(animales)}
										variant="primary"
									>
										Agregar todos <i className="fa-solid fa-plus"></i>
									</Button>
								</Col>
							</Row>
							<Table responsive striped bordered hover size="sm">
								<thead>
									<tr>
										{cols.map((col) => (
											<th key={col.key}>{col.label}</th>
										))}
										<th></th>
									</tr>
								</thead>
								<tbody style={loading ? { opacity: 0.3 } : null}>
									{animales.map((animal) => {
										const noSeleccionable = seleccionados.noSeleccionablesIds?.some(
											(s) => s === animal.id
										)

										const yaSeleccionado = seleccionados.todos.some(
											(s) => s.id === animal.id
										)

										return (
											<tr key={animal.id} className={mostrarEliminados && seleccionados.eliminadosIds.includes(animal.id) ? 'animal-eliminado' : ''}>
												{cols.map((col) => (
													<td key={col.key}>{animal[col.key]}</td>
												))}
												<td className="text-center">
													<Button size="sm"
														onClick={(e) => seleccionarAnimal(animal)}
														variant={(!yaSeleccionado && noSeleccionable) ? "secondary" : "success"}
														disabled={loading || noSeleccionable || yaSeleccionado}
													//) || (max && selectedList.length >= max)}
													>
														<i className="fa-solid fa-plus"></i>
													</Button>
												</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
							{loadingInicial && (
								<div className="mb-3"><LoadingSpinner /></div>
							)}
							<Row>
								<Col>
									<Paginacion
										pagina={pagina}
										numPaginas={Math.ceil(numResultados / deACuantos) || 1}
										setPagina={cambiarPagina}
									/>
								</Col>
								<Col className="text-end">
									<small>
										Mostrar de a
										<Form.Group className="mb-3 d-inline-block mx-2" controlId="de-a-cuantos">
											<Form.Select
												size='sm'
												value={deACuantos}
												onChange={(e) => cambiarDeACuantos(e.target.value)}
											>
												{opcionesDeACuantos.map((num) => {
													return (
														<option value={num} key={`cuantos-${num}`}>
															{num}
														</option>
													)
												})}
											</Form.Select>
										</Form.Group>
										resultados.
									</small>
								</Col>
							</Row>
						</>
					)}
				</Card.Body>
			</Card>
		</div>
	)
}
