import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import ListaAnimalesDeTarea from '../ListaAnimalesDeTarea'
import SelectorAnimal from '../SelectorAnimal'
import AnimalSeleccionado from '../AnimalSeleccionado'


export default function SanitacionProcesar(props) {
	const { config, tarea, onClose, onProcesar, verFinalizarTarea, setCambiosData } = props

	const [nuevosProcesados, setNuevosProcesados] = useState([])
	const [noProcesadosIds, setNoProcesadosIds] = useState([])
	const [tipoIdentificacion, setTipoIdentificacion] = useState('numCaravana')
	const [numIdentificacion, setNumIdentificacion] = useState('')
	const [seleccionado, setSeleccionado] = useState(null)
	const [mostrarMensajeYaProcesado, setMostrarMensajeYaProcesado] = useState(false)
	const [mostrarMensajeAccionYaTomada, setMostrarMensajeAccionYaTomada] = useState(false)
	// Para mantener lo seleccionado entre animales
	const [datosSeleccionados, setDatosSeleccionados] = useState({
		fecha: tarea.fechaParaCuando,
		medicamentos: {},
		profesional: ''
	})

	const mantenerSeleccionKeys = Object.keys(datosSeleccionados)
	const sinCambios = nuevosProcesados.length === 0 && noProcesadosIds.length === 0

	useEffect(() => {
		if (!sinCambios) {
			let textoCambios = ''
			if (nuevosProcesados.length) {
				textoCambios += `${nuevosProcesados.length} ${(nuevosProcesados.length === 1 ? 'animal procesado' : 'animales procesados')}`
			}

			if (noProcesadosIds.length) {
				textoCambios += textoCambios ? ' y ' : ''
				textoCambios += `${noProcesadosIds.length} ${(noProcesadosIds.length === 1 ? 'dato anterior eliminado' : 'datos anteriores eliminados')}`
			}

			setCambiosData({
				nombre: tarea.nombre,
				texto: textoCambios
			})
		} else {
			setCambiosData(null)
		}
	}, [nuevosProcesados, noProcesadosIds])

	const handleSave = () => {
		const serverData = {
			id: tarea.id,
			listas: [
				{
					id: tarea.listas[0].id, // id de la lista
					marcarProcesados: nuevosProcesados,
					marcarNoProcesadosIds: noProcesadosIds
				},
			],
		}

		onClose(true)
		onProcesar(serverData)
	}

	const handleClose = () => {
		onClose()
	}

	const handleSeleccionarAnimal = (animal) => {
		const yaProcesadoIndex = nuevosProcesados.findIndex((a) => a.id === animal.id)
		if (yaProcesadoIndex !== -1) {
			animal.nuevosDatos = nuevosProcesados[yaProcesadoIndex]
		} else {
			animal.nuevosDatos = {
				id: animal.id,
				fecha: datosSeleccionados.fecha,
				medicamentos: datosSeleccionados.medicamentos,
				profesional: datosSeleccionados.profesional,
				sanitacionId: tarea.sanitacion.id,
			}
		}

		animal.nuevosDatos['datosCompletados'] = verSiCompletadoDatosAnimal(animal.nuevosDatos)
		setSeleccionado(animal)
	}

	const changeNumIdentificacion = (numId) => {
		setNumIdentificacion(numId)

		/*
		* Si el animal ya fue procesado y no está entre los a eliminar, entonces muestra un aviso de que ya fue procesado y no selecciona el animal.
		* Si el animal fue recién procesado o está marcado para eliminar, muestra un aviso de la acción ya tomada, pero selecciona el animal
		*/
		const animal = tarea.listas[0].animales.find((a) => a[tipoIdentificacion] === numId)
		if (animal) {
			if (animal.procesado) {
				setMostrarMensajeYaProcesado(true)
				setSeleccionado(null)
			} else {
				setMostrarMensajeYaProcesado(false)

				const yaProcesadoIndex = nuevosProcesados.findIndex((a) => a.id === animal.id)
				const noProcesadoIndex = noProcesadosIds.findIndex((a) => a === animal.id)
				if (yaProcesadoIndex !== -1 || noProcesadoIndex !== -1) {
					setMostrarMensajeAccionYaTomada(true)
				} else {
					setMostrarMensajeAccionYaTomada(false)
				}
				handleSeleccionarAnimal(animal)
			}
		} else {
			setSeleccionado(null)
			setMostrarMensajeAccionYaTomada(false)
			setMostrarMensajeYaProcesado(false)
		}
	}

	const handleChangeData = (valor, cual, medicamentoId = null) => {
		const copia = { ...seleccionado }
		copia.nuevosDatos = { ...seleccionado.nuevosDatos }

		if (medicamentoId !== null) {
			// Manejo de cambios en medicamentos
			const medicamentos = { ...copia.nuevosDatos.medicamentos }

			if (cual === 'checkMedicamento') {
				if (valor) {
					medicamentos[medicamentoId] = '' // Inicializar dosis vacía
				} else {
					delete medicamentos[medicamentoId] // Eliminar medicamento si se desmarca
				}
			} else if (cual === 'dosis') {
				medicamentos[medicamentoId] = valor // Actualizar dosis
			}

			copia.nuevosDatos.medicamentos = medicamentos

			if (mantenerSeleccionKeys.includes('medicamentos')) {
				setDatosSeleccionados(prev => ({
					...prev,
					medicamentos: medicamentos
				}))
			}
		} else {
			// Manejo de otros campos (fecha, profesional)
			copia.nuevosDatos[cual] = valor

			if (mantenerSeleccionKeys.includes(cual)) {
				setDatosSeleccionados(prev => ({
					...prev,
					[cual]: valor
				}))
			}
		}

		copia.nuevosDatos['datosCompletados'] = verSiCompletadoDatosAnimal(copia.nuevosDatos)
		setSeleccionado(copia)
	}

	const verSiCompletadoDatosAnimal = (datos) => {
		return datos.fecha !== '' &&
			Object.keys(datos.medicamentos).length > 0 &&
			Object.values(datos.medicamentos).every(dosis => dosis.trim() !== '')
	}

	const deseleccionar = () => {
		setSeleccionado(null)
		setNumIdentificacion('')
		setMostrarMensajeAccionYaTomada(false)
		setMostrarMensajeYaProcesado(false)
	}

	const handleConfirmarAnimal = () => {
		const yaProcesadoIndex = nuevosProcesados.findIndex((a) => a.id === seleccionado.id)

		const procesados = [...nuevosProcesados]
		if (yaProcesadoIndex !== -1) {
			procesados[yaProcesadoIndex] = seleccionado.nuevosDatos
		} else {
			procesados.push(seleccionado.nuevosDatos)
		}

		setNuevosProcesados(procesados)
		deseleccionar()
	}

	const handleEliminarProcesadoDeAnimal = (animal) => {
		setNoProcesadosIds([...noProcesadosIds, animal.id])
		deseleccionar()
	}

	const handleCancelarNuevoProcesado = (animal) => {
		const yaProcesadoIndex = nuevosProcesados.findIndex((a) => a.id === animal.id)
		const procesadosCopy = [...nuevosProcesados.slice(0, yaProcesadoIndex), ...nuevosProcesados.slice(yaProcesadoIndex + 1)]
		setNuevosProcesados(procesadosCopy)
		deseleccionar()
	}

	const handleCancelarEliminarProcesadoDeAnimal = (animal) => {
		const noProcesadoIndex = noProcesadosIds.findIndex((a) => a === animal.id)
		const noProcesadosCopy = [...noProcesadosIds.slice(0, noProcesadoIndex), ...noProcesadosIds.slice(noProcesadoIndex + 1)]
		setNoProcesadosIds(noProcesadosCopy)
		deseleccionar()
	}

	const handleVerFinalizarTarea = () => {
		verFinalizarTarea(tarea)
	}

	return (
		<Container>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col className="ms-2">
							<h5>{tarea.nombre}</h5>
						</Col>
						<Col className="me-2 text-end">
							<Button
								onClick={handleVerFinalizarTarea}
								disabled={!sinCambios}
							>
								Finalizar Tarea
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col md="7">
							<div
								style={{
									height: 'calc(100vh - 330px)',
									overflowY: 'auto',
									position: 'relative'
								}}
							>
								<ListaAnimalesDeTarea
									animales={tarea.listas[0].animales}
									recienProcesados={nuevosProcesados}
									recienEliminadoProcesadosIds={noProcesadosIds}
									onSeleccionar={handleSeleccionarAnimal}
									onEliminarProcesado={handleEliminarProcesadoDeAnimal}
									onCancelarNuevoProcesado={handleCancelarNuevoProcesado}
									onCancelarEliminarProcesado={handleCancelarEliminarProcesadoDeAnimal}
								/>
							</div>
						</Col>
						<Col md="5">
							<div className="sticky-top">
								<SelectorAnimal
									tipoIdentificacion={tipoIdentificacion}
									setTipoIdentificacion={setTipoIdentificacion}
									numIdentificacion={numIdentificacion}
									changeNumIdentificacion={changeNumIdentificacion}
								/>
								<AnimalSeleccionado
									key={seleccionado?.id}
									animal={seleccionado}
									onChangeData={handleChangeData}
									onConfirmar={handleConfirmarAnimal}
									yaProcesado={mostrarMensajeYaProcesado}
									accionYaTomada={mostrarMensajeAccionYaTomada}
								>
									<Row>
										<Col>
											<Form.Label className="mt-1 mb-0">Medicamentos y dosis</Form.Label>
											{tarea.sanitacion.configuracionMedicamentos.map((medicamento, i) => {
												const isChecked = seleccionado?.nuevosDatos.medicamentos?.[medicamento.id] !== undefined
												const dosis = seleccionado?.nuevosDatos.medicamentos?.[medicamento.id] || ''

												return (
													<Row key={`medicamento-${i}`} className="mb-2 align-items-center">
														<Col xs="7">
															<Form.Group className="d-flex align-items-center">
																<Form.Check
																	id={`check-medicamento-${i}`}
																	type='checkbox'
																	inline
																	checked={isChecked}
																	onChange={(e) => handleChangeData(
																		e.target.checked,
																		'checkMedicamento',
																		medicamento.id
																	)}
																/>
																<Form.Label htmlFor={`check-medicamento-${i}`} className="mb-0">
																	{medicamento.nombre}
																</Form.Label>
															</Form.Group>
														</Col>
														<Col xs="5">
															<Form.Control
																type="text"
																size="sm"
																value={dosis}
																onChange={(e) => handleChangeData(
																	e.target.value,
																	'dosis',
																	medicamento.id
																)}
																disabled={!isChecked}
																placeholder="Dosis"
															/>
														</Col>
													</Row>
												)
											})}
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group className="col mb-3" controlId="sanitacion-profesional">
												<Form.Label className="mb-1">Profesional</Form.Label>
												<Form.Control
													type="text"
													size="sm"
													value={seleccionado?.nuevosDatos.profesional || ''}
													onChange={(e) => handleChangeData(e.target.value, 'profesional')}
												/>
											</Form.Group>
										</Col>
									</Row>
								</AnimalSeleccionado>
							</div>
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer>
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={sinCambios}
							>
								Procesar tarea
							</Button>
						</Col>
					</Row>
				</Card.Footer>
			</Card>
		</Container>
	)
}